import Fuse from 'fuse.js'
import { format, parse, addMonths, subMonths, getMonth, formatISO } from 'date-fns'
import { latinize } from 'modern-diacritics'

export function search(needle, haystack, keys = [], results = 3, options = {}) {
  const fuse = new Fuse(haystack, {
    keys,
    threshold: 0.2,
    ...options
  })

  return fuse.search(needle)
    .map(hit => hit.item)
    .slice(0, results)
}

export function formatMoney(amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  return formatter.format(amount)
}

/*
  This function takes one of 3 date formats we get back from SFDC and converts
  them into an ISO 8601 format ie... 2023-06-01
*/
export function prettyDate(date) {
  // Capturing NULL values received
  if (date === null) {
    console.error('Date received in prettyDate function inside src/js/utils.js is `NULL`.')
    return ''
  }

  // Date string is in ISO STRING format ( ex... 2022-08-22T00:00:00.000+0000 )
  if (date.length > 10) {
    return formatISO(new Date(date), {representation: 'date'})
  }

  // Date string is in OBJECT format ( ex.... Wed Oct 08 2022 00:00:00 GTM-0400 )
  else if(typeof date === 'object') {
    return format(new Date(date),'yyyy-MM-dd')
  }

  // Date string is already in correct format ( ex... 2022-08-22 )
  else {
    return date
  }
}

/*
  This function takes an ISO 8601 formatted date and returns Date String
*/
export function prettyDateToDateString(date) {
  // Capturing NULL values received
  if (date === null) {
    console.error('Date received in prettyDateToDateString function inside src/js/utils.js is `NULL`.')
    return ''
  }

  // Returns Date from 2023-06-01 to Date String like Thur June 01 2023 00:00:00 GTM-0400
  return new Date(parse(date, 'yyyy-MM-dd', new Date()))
}


/**
 * A functional approach to "match expressions". It uses syntax similar to
 * a switch statement, but allows for return values to perform assignments
 * conditionally.
 *
 * Example:
 *
 * const isFruit = match('apple', {
 *   apple: true,         // isFruit === true
 *   orange: true,
 *   potato: false,
 *   onion: () => false   // You can use functions!
 * })
 *
 * @param {*} value
 * @param {*} lookup
 * @param  {...any} args
 */
export function match(value, lookup, ...args) {
  if (value in lookup) {
    const ret = lookup[value]
    return typeof ret === 'function' ? ret(...args) : ret
  }

  if (lookup.default) {
    return typeof lookup.default === 'function' ? lookup.default(...args) : lookup.default
  }

  const error = new Error(
    `Tried to handle "${value}" but there is no handler defined. Only defined handlers are: ${Object.keys(lookup).map(key => `"${key}"`).join(', ')}`
  )

  if (Error.captureStackTrace) {
    Error.captureStackTrace(error, match)
  }

  throw error
}

export const quarter = {
  fromDate(date) {
    date = new Date(date)

    // MONTHS ARE ZERO INDEXED WITH DATE-FNS
    const q = [2,3,3,3,4,4,4,1,1,1,2,2][getMonth(date)]
    const fy = getMonth(addMonths(date, 1)) >= 8 || getMonth(addMonths(date, 1)) == 0 ? Number(format(date, 'yy')) + 1 : Number(format(date, 'yy'))

    return [q, fy]
  },

  toDate(quarter) {
    let q, fy

    if (typeof quarter === 'string') {
      [, q, fy] = quarter.match(/Q(\d)FY(\d\d)/)
    } else if (Array.isArray(quarter) && quarter.length === 2) {
      [q, fy] = quarter
    }

    if (q < 1 || q > 4) {
      return
    }

    const month = [8, 11, 2, 5][q - 1]
    const year = month + 1 >= 8 ? parseInt(fy) - 1 : fy

    return format(new Date(`${month}/01/${year}`), 'yyyy-MM-dd')
  },

  startDate(date) {
    return this.toDate(this.fromDate(date))
  },

  next(count = 1) {

    return  [...Array(count).keys()].map(offset => {
      const quarter = this.fromDate(addMonths(new Date(), offset * 3 ))

      return {
        quarter,
        startDate: this.toDate(quarter)
      }
    })
  },

  previous(count = 1) {

    return  [...Array(count).keys()].map(offset => {
      const quarter = this.fromDate(subMonths(new Date(), offset * 3 ))

      return {
        quarter,
        startDate: this.toDate(quarter)
      }
    })
  }
}

/*
  This function takes an attachment name test_attachment.doc and maps through
  an extension object to find appropriate icon from Iconify and returns
  to the frontend.
*/
export function attachmentIcon(attachmentName) {
  let icons = {
    default: 'guidance:paper',
    xls: 'vscode-icons:file-type-excel',
    pdf: 'vscode-icons:file-type-pdf2',
    ppt: 'vscode-icons:file-type-powerpoint',
    doc: 'vscode-icons:file-type-word',
    txt: 'fxemoji:documentwithtext',
    jpg: 'fxemoji:documentwithpicture',
    png: 'iwwa:file-png',
    zip: 'vscode-icons:file-type-zip',
    ics: 'flat-color-icons:electronics',
    svg: 'skill-icons:svg-light',
    csv: 'catppuccin:csv',
  }

  // Take document name and capture characters after last period (ie... '.pdf') returns 'pdf'
  let attachmentType = attachmentName.split('.').pop()

  // If attachmentType has an 'X' at the end (ie... 'xlsx') is converted to 'xls'
  if (attachmentType.slice(-1) === 'x') {
    attachmentType = attachmentType.slice(0,-1)
  }

  return icons[attachmentType] || icons.default
}

/*
  This function normalizes text by removing accents (diacritics) from strings to latin characters
  ie. 'ỆᶍǍᶆṔƚÉ áéíóúýčďěňřšťžů' becomes 'ExAmPlE aeiouycdenrstzu'

  - we have contacts for example where names have accents and this normalizes it for filter/comparing
*/
export function normalizeText(text) {
  return latinize(text)
}

/*
  Determine if value is a primitive data type.
  ie... string, number, bigint, boolean, undefined, symbol, null
*/
export function isPrimitive(value) {
  return value === Object(value) ? false : true
}

/*
  Converts long strings into multiple lines.
  ie... use case exmaple like CSV export and text areas which are really long.
*/
export function convertLongStringIntoMultipleLines(string, chunkSize = 10) {
  if (!string) return

  let convertStringIntoArrayOfWords = string.split(' '),
    stringArrayIntoChunks = []

  while(convertStringIntoArrayOfWords.length > 0) {
    stringArrayIntoChunks.push(convertStringIntoArrayOfWords.splice(0, chunkSize))
  }

  return `${[...stringArrayIntoChunks].map(item => item.join(' ')).join('\n')}`
}

/*
  Throw an uncaught exception in the console if condition is false
*/
export function assert(condition, message) {
  if (!condition) {
    throw new Error(message || 'Assertion failed')
  }
}
